export const enum QUESTIONS_IDS {
    PRIMARY_GOAL = "primary_goal",
    BIGGEST_DRIVE = "biggest_drive",
    FITNESS_EXPERIENCE = "fitness_experience",
    OBSTACLES = "obstacles",
    PROMO_HELP = "promo_help",
    PROMO_ZING_AI = "promo_zing_ai",
    PUSH_UPS = "push_ups",
    SQUATS = "squats",
    OTHER_SPORTS = "other_sports",
    EQUIPMENT_BY_ENVIRONMENT = "equipment_by_environment",
    TARGET_AREAS = "target_areas",
    LOCATION = "location",
    HEALTH_PROBLEMS = "health_problems",
    BODY_TYPE = "body_type",
    DESIRED_BODY = "desired_body",
    CURRENT_WEIGHT = "current_weight",
    BODY_FAT = "body_fat",
    EVENT_MOTIVATION = "event_motivation",
    WEB_CYCLE_DATE = "web_cycle_date",
    WEB_CYCLE = "web_cycle",
    WEB_REPRODUCTIVE_STAGE = "web_reproductive_stage",
    WEB_PERIOD_DAYS = "web_period_days",
    WEB_CYCLE_DAYS = "web_cycle_days",
    WEB_CYCLE_SYMPTOMS = "web_cycle_symptoms",
    WEB_CYCLE_PHASE_CHART = "web_cycle_phase_chart",
    TARGET_WEIGHT = "target_weight",
    DIET_TYPE = "nutrition_book_diet_type",
    PROMO_SCREEN_WORKOUT_SMARTLY = "promo_screen_workout_smartly",
    PROMO_SCREEN_REPRODUCTIVE_STAGE = "promo_reproductive_stage",
    ACTIVITY_LEVEL = "activity_level",
    SLEEP_AMOUNT = "sleep_amount",
    FITNESS_LEVEL = "fitness_level",
    TRAIN_3_MONTHS = "train_3months",
    ACTIVITY_ENGAGED = "activity_engaged",
    EATING_HABITS = "eating_habits",
    TRAINING_TRIGGER = "training_trigger",
    AGE = "age",
    HEIGHT = "height",
    EVENT_DATE = "event_date",
    GYM_TYPE = "gym_type",
    WATER_CONSUMPTION = "water_consumption",
    BMI_RESULTS = "bmi_results",
    FEEL_BETWEEN_MEALS = "feel_between_meals",
    SUCCESS_MEASURE = "success_measure",
    TRACK_STRENGTH = "track_strength",
    HOPE_TO_FEEL = "hope_to_feel",
    IMPORTANT_ELEMENTS = "important_elements",
    PROTECT_AGAINST_AGING = "protect_against_aging",
    SPORT_PREFER = "sport_prefer",
    DESIRED_WORKOUT_TIME = "desired_workout_time",
    CORTISOL = "level_of_cortisol_&_testosterone",
    WEIGHT_DEAD_LIFT = "weight_dead_lift",
    WEIGHT_BENCH_PRESS = "weight_bench_press",
    MOTIVATION = "motivation",
    PREVENT_EXERCISE_CONSISTENTLY = "prevent_exercise_consistently",
    ABILITY_TO_STAY_MOTIVATED = "ability_to_stay_motivated",
    ACHIEVE_FITNESS_GOAL = "achieve_fitness_goal",
    MOTIVATE_MOST = "motivate_most",
    COPE_STRESS = "cope_stress",
    DAILY_WALK = "daily_walk",
    PLAN_FAILED = "plans_failed",
    STATEMENT_REQUIRE_GUIDANCE = "statement_require_guidance",
    STATEMENT_SUPPORT_BOOSTS_MOTIVATION = "statement_support_boosts_motivation",
    WEIGHT_LOSS_GOAL = "weight_loss_goal",
    PACE = "pace",
    PROMO_SOCIAL_PROOF = "promo_social_proof",
    BREATH_LOSE = "breath_lose",
    ADD_CARDIO_TO_PLAN = "add_cardio_to_plan",
    SELECT_CARDIO_MACHINES = "select_cardio_machines",
    // Weight Predict
    INITIAL_WEIGHT_PREDICT = "initial_weight_predict",
    FIRST_WEIGHT_PREDICT_DATE = "first_weight_predict_date",
    WEIGHT_PREDICT_DATE = "weight_predict_date",
    INTERMEDIATE_WEIGHT_LOSS = "intermediate_weight_loss",
    INTERMEDIATE_WEIGHT_LOSS_DATE = "intermediate_weight_loss_date",
    SHOW_INTERMEDIATE_RESULT = "show_intermediate_result",
    TOO_EXPENSIVE_FITNESS_PROGRAM_FEE = "too_expensive_fitness_program_fee",
    // Seniors Funnel
    ASPECTS_MOST_CONCERN = "aspects_most_concern",
    FEEL_IN_YOUR_CLOTHES = "feel_in_your_clothes",
    STATEMENT_PROGRAM_UNDERSTANDS_MY_LIMITATIONS = "statement_program_understands_my_limitations",
    STATEMENT_SAFE_GUIDANCE = "statement_safe_guidance",
    CONDITIONS_AFFECT_DAILY_ACTIVITIES = "conditions_affect_daily_activities",
    GOING_THROUNG_MENOPAUSE = "going_through_menopause",
    MENOPAUSE_SYMPTOMS = "menopause_symptoms",
    WORKED_WITH_HEALTH_PROFESSIONALS = "worked_with_health_professionals",
    CONCERNS_YOU_MOST = "concerns_you_most",
    TRIED_TO_IMPROVE_YOUR_FITNESS = "tried_to_improve_your_fitness",
    FITNESS_PLANS_DIFFICULT_TO_FOLLOW = "fitness_plans_difficult_to_follow",
    WORKOUT_INTENSITY = "workout_intensity",
    SUPPORT_IN_FITNESS = "support_in_fitness",
    // Calisthenics
    CALISTHENICS_GOAL_FEEDBACK = "calisthenics_goal_feedback",
    CALISTHENICS_OBSTACLE_FEEDBACK = "calisthenics_obstacle_feedback",
    CALISTHENICS_RESTRICTIONS_FEEDBACK = "calisthenics_restrictions_feedback",
    CALISTHENICS_LEVEL_FEEDBACK = "calisthenics_level_feedback",
    CALISTHENICS_TRAINING_FREQUENCY_FEEDBACK = "calisthenics_training_frequency_feedback",
    FAMILIAR_CALISTHENICS = "familiar_calisthenics",
    // New Qs sequence
    UNHAPPY_ABOUT_YOUR_CURRENT_FITNESS = "unhappy_about_your_current_fitness",
    DRIVES_TO_ACHIEVE_GOALS = "drives_to_achieve_goals",
    HOLDING_BACK_FROM_FITNESS_GOALS = "holding_back_from_fitness_goals",
    STATEMENT_TIRED_OF_GUESSING = "statement_tired_of_guessing",
    YOU_ARE_ON_THE_RIGHT_TRACK = "you_are_on_the_right_track",
    ACTIVE_USERS_AND_REVIEWS = "active_users_and_reviews",
    FITNESS_TRACKER_DEVICE = "fitness_tracker_device",
    // Pilates
    TRIED_WALL_PILATES = "tried_wall_pilates",
    DISCOVER_ZING_WALL_PILATES = "discover_zing_wall_pilates",
    PILATES_MAKES_YOU_MORE_ENERGIZED = "pilates_makes_you_more_energized",
    STRETCHING_EXERCISE = "stretching_exercise",
    FORWARD_LUNGE_EXERCISE = "forward_lunge_exercise",
    WALL_SIT_EXERCISE = "wall_sit_exercise",
    WALL_LUNGE_STRENGTH_EXERCISE = "wall_lunge_stretch_exercise",
    WHY_PEOPLE_GIVE_UP = "why_people_give_up",
    // Beginners
    TOP_CONCERN_ABOUT_NEW_ROUTINE = "top_concern_about_new_routine",
    CONFIDENCE_IN_STARTING_FITNESS_ROUTINE = "confidence_in_starting_fitness_routine",
    REQUIRE_STRUCTURE_TO_STAY_MOTIVATED = "require_structure_to_stay_motivated",
    FEELING_SUPPORTED_BOOSTS_MOTIVATION = "feeling_supported_boosts_motivation",
    NOTICEABLE_RESULT_FOR_BEGINNERS = "noticeable_result_for_beginners",
    MILLIONS_NEWBIES_STARTED_WITH_ZING = "millions_newbies_started_with_zing",
    // Core Users
    MILLIONS_FITNESS_ENTHUSIASTS_WORK_OUT_WITH_ZING = "millions_fitness_enthusiasts_work_out_with_zing",
    MEASURABLE_PROGRESS = "measurable_progress",
    FRUSTRATES_YOU_MOST = "frustrates_you_most",
    APPROACH_THAT_FITS_YOU = "approach_that_fits_you",
    BEST_DESCRIBED_YOUR_LIFESTYLE = "best_described_your_lifestyle",
    PROMO_CORE_REVIEWS = "promo_core_reviews",
    // Core Users Funnel V2
    UNLOCK_INNER_MOTIVATION = "unlock_inner_motivation",
    OVERCOME_YOUR_HURDLES = "overcome_your_hurdles",
    NO_MORE_GUESSWORK = "no_more_guesswork",
    FITS_YOUR_LIFE = "fits_your_life",
    YOUR_WORKOUT_OPTIMIZED = "your_workout_optimized",
    YOUR_WHY_IS_YOUR_POWER = "your_why_is_your_power",
    MAXIMIZING_WORKOUT_WINDOW = "maximizing_workout_window",
    SEAMLESS_INTEGRATION = "seamless_integration",
    RIGHT_WHERE_YOU_NEED_TO_BE = "right_where_you_need_to_be",
    LEVELING_UP_YOUR_PAST_EXPERIENCES = "leveling_up_your_past_experiences",
}

export const enum QuestionTypes {
    AIBodyScan = "AIBodyScan",
    YesNo = "yes/no",
    YesNoWithImage = "yesNoWithImage",
    BmiPromoMale = "bmiPromoMale",
    BmiPromoFemale = "bmiPromoFemale",
    Radio = "radio",
    RadioCards = "radioCards",
    Checkbox = "checkbox",
    CheckboxInline = "checkbox_inline",
    CheckboxImageLeft = "checkbox_image_left",
    RadioImageLeft = "radio_image_left",
    PromoHelp = "promoHelp",
    PromoScreen = "promoScreen",
    Input = "input",
    Cortisol = "cortisol_screen",
    Range = "range",
    Date = "date",
    Rate = "rate",
    ReviewsPromo = "reviewsPromo",
    RangeNumber = "rangeNumber",
    PromoCyclePhase = "promoCyclePhase",
    PromoZingAi = "promoZingAi",
    PromoSocialProof = "promoSocialProof",
    InitialWeightPredict = "initialWeightPredict",
    CurrencyInput = "currencyInput",
    CalisthenicsFeedbackLoops = "calisthenicsFeedbackLoops",
    ReviewWithLoader = "reviewWithLoader",
    ReviewAndActiveUsers = "reviewAndActiveUsers",
    LikeDislike = "likeDislike",
    PromoWithUserReview = "promoWithUserReview",
    PromoNewbies = "promoNewbies",
    PromoFitnessEnthusiasts = "promoFitnessEnthusiasts",
    PromoCoreReviews = "promoCoreReviews",
    MotivationalScreen = "motivational_screen",
}

export const enum CheckboxType {
    circular = "circular",
    square = "square",
    tileSquare = "tileSquare",
}
