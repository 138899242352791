import React, {
    createContext,
    memo,
    PropsWithChildren,
    useCallback,
    useContext,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
import { useMatch } from "react-router-dom";

import { setAnalyticsUserProperties } from "@/constants/amplitude";
import {
    CountryCodes,
    CurrencyCodeByCountryCode,
    CurrencyCodes,
    CurrencySymbolsByCurrencyCode,
} from "@/constants/currency";
import { ABSTRACT_API_KEY, IS_PROD_BUILD } from "@/constants/env";
import { Routes as AppRoutes } from "@/constants/routes";
import { GeolocationAction, GeolocationActions, GeolocationResponse } from "@/types/geolocation";
import geolocationReducer from "@/utils/geolocationReducer";

import { debounce } from "./getDebouncedFunction";
import { localStorageManager, LocalStorageManagerKeys } from "./localStorageManager";
import { countryCodeParam } from "./settings";

const GEOLOCATION_API = `https://ipgeolocation.abstractapi.com/v1/?api_key=${ABSTRACT_API_KEY}`;

const DEFAULT_COUNTRY = (countryCodeParam as CountryCodes) || CountryCodes.US;
const DEFAULT_CURRENCY = CurrencyCodeByCountryCode[DEFAULT_COUNTRY] || CurrencyCodes.USD;

export const CurrencyContext = createContext({
    country: DEFAULT_COUNTRY,
    currency: DEFAULT_CURRENCY,
    currencySymbol: CurrencySymbolsByCurrencyCode[DEFAULT_CURRENCY],
    isLoaded: false,
    loadCurrencies: async () => {
        return;
    },
    ipAddress: "",
    city: "",
    postalCode: "",
    region: "",
});

const GeolocationProvider = ({ children }: PropsWithChildren) => {
    const savedData = localStorageManager.getStoredItem(LocalStorageManagerKeys.GEOLOCATION_DATA);
    const [geolocation, dispatchGeolocation] = useReducer(
        geolocationReducer,
        savedData ?? {
            country: DEFAULT_COUNTRY,
            city: "",
            postalCode: "",
            currency: DEFAULT_CURRENCY,
            ipAddress: "",
            region: "",
        }
    );

    const [isLoaded, setSetLoaded] = useState(!!countryCodeParam || !!savedData);
    const isLoadingRef = useRef(false);

    const isOfferUrl = useMatch({
        path: `/${AppRoutes.Both}/${AppRoutes.Offer}`,
        end: false,
    });

    const isPromoUrl = useMatch({
        path: `/${AppRoutes.PromoPaywall}`,
        end: false,
    });
    const isFriendsFlow = useMatch({
        path: `/${AppRoutes.Both}/${AppRoutes.Friends}`,
        end: false,
    });
    const isMexUrl = useMatch({
        path: `/${AppRoutes.Both}/${AppRoutes.Mex}`,
        end: false,
    });

    const usdCurrencyOnly =
        isOfferUrl ||
        isPromoUrl ||
        (isFriendsFlow &&
            [CountryCodes.AR, CountryCodes.CL, CountryCodes.BR].includes(
                geolocation?.country as CountryCodes
            ));
    const mxnCurrencyOnly = !!isMexUrl;

    const getCurrency = useCallback(
        (currency: CurrencyCodes | undefined) => {
            if (usdCurrencyOnly) {
                return CurrencyCodes.USD;
            } else if (mxnCurrencyOnly) {
                return CurrencyCodes.MXN;
            }
            return currency ?? DEFAULT_CURRENCY;
        },
        [usdCurrencyOnly, mxnCurrencyOnly]
    );

    const currencySymbol = useMemo(() => {
        const currencySymbol = geolocation?.currency
            ? CurrencySymbolsByCurrencyCode[geolocation.currency]
            : CurrencySymbolsByCurrencyCode[DEFAULT_CURRENCY];

        if (usdCurrencyOnly) {
            return CurrencySymbolsByCurrencyCode[CurrencyCodes.USD];
        } else if (mxnCurrencyOnly) {
            return CurrencySymbolsByCurrencyCode[CurrencyCodes.MXN];
        }
        return currencySymbol;
    }, [geolocation.currency, usdCurrencyOnly, mxnCurrencyOnly]);

    const getCurrencyFromGeoData = useCallback(
        ({ geoData, isOfferFlow }: { geoData: GeolocationResponse; isOfferFlow: boolean }) => {
            if (isOfferFlow) {
                return CurrencyCodes.USD;
            } else if (geoData.currency.currency_code === CurrencyCodes.EUR) {
                return CurrencyCodes.EUR;
            } else return CurrencyCodeByCountryCode[geoData.country_code] || DEFAULT_CURRENCY;
        },
        []
    );

    const loadCurrencies = useCallback(async () => {
        if (!countryCodeParam && !isLoaded && !isLoadingRef.current) {
            try {
                isLoadingRef.current = true;
                const response = await fetch(GEOLOCATION_API);
                const data: GeolocationResponse = await response.json();
                const geoData: GeolocationAction = {
                    type: GeolocationActions.SET_DATA,
                    currency: getCurrencyFromGeoData({ geoData: data, isOfferFlow: !!isOfferUrl }),
                    country: data.country_code,
                    ipAddress: data.ip_address,
                    postalCode: data.postal_code,
                    city: data.city,
                    region: data.region,
                };
                dispatchGeolocation(geoData);
                localStorageManager.storeItem(LocalStorageManagerKeys.GEOLOCATION_DATA, geoData);
                setAnalyticsUserProperties({
                    web_currency: getCurrency(geoData.currency),
                });
            } catch (e) {
                console.error(e);
            } finally {
                setSetLoaded(true);
                isLoadingRef.current = false;
            }
        }
    }, [isLoaded, getCurrencyFromGeoData, isOfferUrl, getCurrency]);

    const debouncedLoadCurrencies = debounce(loadCurrencies, 2000) as () => Promise<void>;

    return (
        <CurrencyContext.Provider
            value={{
                country: geolocation.country ?? DEFAULT_COUNTRY,
                currency: getCurrency(geolocation.currency),
                currencySymbol,
                isLoaded,
                loadCurrencies: IS_PROD_BUILD ? loadCurrencies : debouncedLoadCurrencies,
                ipAddress: geolocation.ipAddress ?? "",
                city: geolocation.city ?? "",
                postalCode: geolocation.postalCode ?? "",
                region: geolocation.region ?? "",
            }}
        >
            {children}
        </CurrencyContext.Provider>
    );
};

export const useCurrency = () => {
    const { currency, currencySymbol, isLoaded, loadCurrencies } = useContext(CurrencyContext);
    return { currency, currencySymbol, isLoaded, loadCurrencies };
};

export const useGeolocation = () => {
    const { ipAddress, country, city, postalCode, region } = useContext(CurrencyContext);
    return { ipAddress, country, city, postalCode, region };
};

export default memo(GeolocationProvider);
