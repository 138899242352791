export const enum WebEventTypes {
    VIEW_EVENT = "web_onboarding_screen_viewed",
    RESULT_EVENT = "web_onboarding_screen_result",
    SKIP_EVENT = "web_onboarding_screen_skipped",
    WEB_BUTTON_CLICK = "web_button_click",
    WEB_LINK_CLICK = "web_link_click",
    PURCHASE_STARTED = "web_purchase_started",
    PURCHASE_COMPLETED = "web_purchase_completed",
    PURCHASE_FAILED = "web_purchase_failed",
    PURCHASE_UPGRADE = "web_purchase_upgrade",
    ERROR_EVENT = "web_error",
    AB_TEST = "web_ab_test",
    PAYWALL_SCROLL = "web_onboarding_screen_scroll",
    PAYWALL_SHOWN = "web_paywall_shown",
    FORCE_LINK = "web_force_link",
    AGE_WARNING = "age_warning_message",
}

export const enum WebEventProps {
    BUTTON_NAME = "button_name",
    SCREEN = "screen",
    RESULT = "result",
    TYPE = "type",
    PRICE = "price",
    METHOD = "method",
    SUB_DURATION = "subDuration",
    INTRO_OFFER = "intro_offer",
    ERROR_TEXT = "error_text",
    PERCENTAGE = "percentage",
    PRODUCT_ID = "product_id",
    CURRENCY = "currency",
}

export type EventPropsType = { [key in WebEventProps]?: string | number | null | boolean };

export const enum EventScreenTypes {
    PRIMARY_GOAL = "primary_goal",
    BIGGEST_DRIVE = "biggest_drive",
    EMAIL = "email",
    EMAIL_CONSENT = "email_consent",
    SHARE_VIDEO = "share_video",
    FIRST_LOADER = "first_loader",
    BOTH_FIRST_LOADER = "both_first_loader",
    PROMO_PAYWALL = "promo_paywall",
    CIRCULAR_LOADER = "circular_loader",
    FIRST_SCREEN_AGE_GROUP = "first_screen_age_group",
    SOCIAL_PROOF = "social_proof",
    AI_WEIGHT_PREDICT = "ai_weight_prediction",
    AI_WEIGHT_FIRST_PREDICT = "ai_weight_first_prediction",
    NAME = "name",
    UPSELL = "upsell",
    UPSELL_DISCOUNT = "upsell_discount",
    NUTRITION_BOOK = "nutrition book",
    DISCOUNT_POPUP = "popup with discount",
    AI_USER_SUMMARY = "ai_user_summary",
    WHATSAPP_CONNECT = "whatsapp_connect",
    WHATSAPP_NUMBER_CONFIRMATION = "whatsapp_number_confirmation",
    WHATSAPP_CONNECT_SUCCESS = "whatsapp_connect_success",
}

export const enum EventInputTypes {
    EMAIL = "email",
}

export const enum EventButtonTypes {
    EMAIL = "email",
    SUBMIT_EMAIL = "submit_email",
    SWIPE_AI_SLIDER = "swipe_ai_slider",
    OPEN_PROFILE = "open_profile",
    CONNECT = "connect",
    NOT_NOW = "not_now",
    CONTINUE = "continue",
}

export const enum EventResultTypes {
    ACCEPT = "accept",
    DECLINE = "decline",
    CLOSE = "close",
    CONTINUE = "continue",
    SKIP = "skip",
    BUY = "buy",
}

export const enum EventLinkTypes {
    privacyPolicy = "privacy_policy",
    refundPolicy = "refund_policy",
    termsOfUse = "terms_of_use",
    supportLink = "support_link",
    faq = "faq",
}

export const enum EventPaymentMethodTypes {
    APPLE_PAY = "applepay",
    CARD = "card",
    PAYPAL = "paypal",
}
