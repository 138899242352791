import React, { Dispatch } from "react";

import { LazyComponentWithPreload, lazyWithPreload } from "@/utils/lazyPreload";
import { localStorageManager, LocalStorageManagerKeys } from "@/utils/localStorageManager";
import { FlowTypes } from "@/utils/providers/types";
import { step } from "@/utils/settings";

const Main = lazyWithPreload(() => import("@/components/Main"));
const NameScreen = lazyWithPreload(() => import("@/components/NameScreen/index"));
const EmailScreen = lazyWithPreload(() => import("@/components/EmailScreen"));
const EmailSubscribeScreen = lazyWithPreload(() => import("@/components/EmailSubscribeScreen"));
const Loader = lazyWithPreload(() => import("@/components/Promo/LoaderPromo"));
const Deeplink = lazyWithPreload(() => import("@/components/Deeplink Screen/Deeplink"));
const BodyScan = lazyWithPreload(() =>
    import("@/components/BodyScan/BodyScan").then(({ BodyScan }) => ({ default: BodyScan }))
);
const Upsell = lazyWithPreload(() => import("@/components/Upsells/Workbook"));
const Bundle = lazyWithPreload(() => import("@/components/Upsells/Bundle/Bundle"));
const ProgressLoader = lazyWithPreload(() => import("@/components/ProgressLoader/ProgressLoader"));
const CircularLoader = lazyWithPreload(() => import("@/components/Loader/CircularLoader"));
const DateChart = lazyWithPreload(() => import("@/components/Promo/DateChart"));
const ActionPlan = lazyWithPreload(() => import("@/components/ActionPlan"));
const AuthForm = lazyWithPreload(() => import("@/components/AuthForm"));
const ChartImage = lazyWithPreload(() => import("@/components/Prepaywall/ChartImage"));
const AIUserSummary = lazyWithPreload(() => import("@/components/Prepaywall/AIUserSummary"));
const PromoPaywall = lazyWithPreload(() => import("@/components/Paywall/PromoPaywall"));
const AIPaywall = lazyWithPreload(() => import("@/components/Paywall/AIPaywall"));
const AIWeightPredict = lazyWithPreload(
    () => import("@/components/AIWeightPredict/WeightPredictScreen")
);
const BodyScanPrepaywall = lazyWithPreload(() => import("@/components/BodyScanPrepaywall"));
const AlreadyWithUs = lazyWithPreload(() => import("@/components/AlreadyWithUs"));
const WhatsappConnectionIntroduction = lazyWithPreload(
    () => import("@/components/WhatsappConnection/Introduction")
);
const WhatsappConnectionConfirmPhone = lazyWithPreload(
    () => import("@/components/WhatsappConnection/ConfirmPhone")
);
const WhatsappConnectionInvitationSent = lazyWithPreload(
    () => import("@/components/WhatsappConnection/InvitationSent")
);
Main.preload();

export const enum COMPONENTS {
    QUESTIONS = "Questions",
    PROGRESS_LOADER = "ProgressLoader",
    FIRST_LOADER = "FirstLoader",
    LOADER = "Loader",
    EMAIL = "Email",
    EMAIL_SUBSCRIBE = "EmailSubscribe",
    CHART = "Chart",
    PAYWALL = "Paywall",
    DEEPLINK = "Deeplink",
    WORKBOOK = "Workbook",
    BODY_SCAN = "BodyScan",
    DATE_CHART = "DateChart",
    AI_PAYWALL = "AiPaywall",
    AUTH_FORM = "AuthForm",
    ACTION_PLAN = "ACTION_PLAN",
    AI_WEIGHT_PREDICT = "AiWeightPredict",
    BODY_SCAN_PREPAYWALL = "BodyScanPrepaywall",
    ALREADY_WITH_US = "AlreadyWithUs",
    NAME = "NameScreen",
    AI_USER_SUMMARY = "AIUserSummary",
    WHATSAPP_CONNECTION_MAIN = "WhatsappConnectionIntroduction",
    WHATSAPP_CONNECTION_PHONE = "WhatsappConnectionConfirmPhone",
    WHATSAPP_CONNECTION_DONE = "WhatsappConnectionInvitationSent",
    BUNDLE = "Bundle",
}

export interface CommonFlowComponentProps {
    setComponent: Dispatch<React.SetStateAction<COMPONENTS>>;
}
export const components: { [key in COMPONENTS]?: LazyComponentWithPreload } = {
    [COMPONENTS.FIRST_LOADER]: Loader,
    [COMPONENTS.QUESTIONS]: Main,
    [COMPONENTS.EMAIL]: EmailScreen,
    [COMPONENTS.EMAIL_SUBSCRIBE]: EmailSubscribeScreen,
    [COMPONENTS.DEEPLINK]: Deeplink,
    [COMPONENTS.BODY_SCAN]: BodyScan,
    [COMPONENTS.WORKBOOK]: Upsell,
    [COMPONENTS.PROGRESS_LOADER]: ProgressLoader,
    [COMPONENTS.LOADER]: CircularLoader,
    [COMPONENTS.DATE_CHART]: DateChart,
    [COMPONENTS.ACTION_PLAN]: ActionPlan,
    [COMPONENTS.AUTH_FORM]: AuthForm,
    [COMPONENTS.CHART]: ChartImage,
    [COMPONENTS.PAYWALL]: PromoPaywall,
    [COMPONENTS.AI_PAYWALL]: AIPaywall,
    [COMPONENTS.AI_WEIGHT_PREDICT]: AIWeightPredict,
    [COMPONENTS.BODY_SCAN_PREPAYWALL]: BodyScanPrepaywall,
    [COMPONENTS.ALREADY_WITH_US]: AlreadyWithUs,
    [COMPONENTS.NAME]: NameScreen,
    [COMPONENTS.AI_USER_SUMMARY]: AIUserSummary,
    [COMPONENTS.WHATSAPP_CONNECTION_MAIN]: WhatsappConnectionIntroduction,
    [COMPONENTS.WHATSAPP_CONNECTION_PHONE]: WhatsappConnectionConfirmPhone,
    [COMPONENTS.WHATSAPP_CONNECTION_DONE]: WhatsappConnectionInvitationSent,
    [COMPONENTS.BUNDLE]: Bundle,
};

export const getInitComponent = ({ flowType }: { flowType: FlowTypes | null }) => {
    if (flowType === FlowTypes.Email) {
        return COMPONENTS.PAYWALL;
    }
    if (localStorageManager.getStoredItem<boolean>(LocalStorageManagerKeys.IS_AUTH_PASSED)) {
        return COMPONENTS.DEEPLINK;
    }
    if (localStorageManager.getStoredItem<boolean>(LocalStorageManagerKeys.IS_AUTH_STEP)) {
        return COMPONENTS.AUTH_FORM;
    }
    if (localStorageManager.getStoredItem<boolean>(LocalStorageManagerKeys.IS_PAYWALL_STEP)) {
        return flowType === FlowTypes.Friends ? COMPONENTS.PAYWALL : COMPONENTS.AI_PAYWALL;
    }
    if (step) {
        return (step.charAt(0).toUpperCase() + step.slice(1)) as COMPONENTS;
    }
    if (flowType === FlowTypes.Both || flowType === FlowTypes.Friends) {
        return COMPONENTS.QUESTIONS;
    }

    return COMPONENTS.QUESTIONS;
};
