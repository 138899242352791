import { Languages } from "@/constants/languages";
import { Routes as AppRoutes } from "@/constants/routes";
import i18n from "@/localization/i18next";

const switchLanguage = () => {
    if (window.location.pathname.startsWith(`/${AppRoutes.Both}/${AppRoutes.Mex}`)) {
        i18n.changeLanguage(Languages.Spanish);
    }
};

export default switchLanguage;
