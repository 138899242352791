import { matchPath } from "react-router-dom";

import { Routes as AppRoutes } from "@/constants/routes";
import { Genders } from "@/types/gender";

const newBothContentRoutes = [
    AppRoutes.AgeLift,
    AppRoutes.AgeWeightLoss,
    AppRoutes.AgeWorkout,
    AppRoutes.BothWorkout,
];

const extractBaseBothPath = (pathname: string) => pathname.split("/").slice(1, 3).join("/");

const enum FunnelTypes {
    both = "both",
    friends = "friends",
    offer = "offer",
    calisthenics = "calisthenics",
    calisthenicsAge = "calisthenics-age",
    pilates = "pilates",
    mex = "mex",
}

type AllFunnelTypes = FunnelTypes | Genders;

export const getFunnelTypeForAnalytics = (gender: Genders): AllFunnelTypes | string => {
    const isBoth = matchPath(
        {
            path: `/${AppRoutes.Both}`,
            end: false,
        },
        window.location.pathname
    );
    const isBothGoogle = matchPath(
        {
            path: `/${AppRoutes.BothGoogle}`,
            end: false,
        },
        window.location.pathname
    );
    const isDynamicBothGoogle = matchPath(
        {
            path: `/${AppRoutes.DynamicBoth}`,
            end: false,
        },
        window.location.pathname
    );
    const isFriends = matchPath(
        {
            path: `/${AppRoutes.Both}/${AppRoutes.Friends}`,
            end: false,
        },
        window.location.pathname
    );
    const isOfferUrl = matchPath(
        {
            path: `/${AppRoutes.Both}/${AppRoutes.Offer}`,
            end: false,
        },
        window.location.pathname
    );
    const isCalisthenicsUrl = matchPath(
        {
            path: `/${AppRoutes.Calisthenics}`,
            end: false,
        },
        window.location.pathname
    );

    const isCalisthenicsAgeUrl = matchPath(
        {
            path: `/${AppRoutes.CalisthenicsAge}`,
            end: false,
        },
        window.location.pathname
    );

    const isBothCalisthenicsUrl = matchPath(
        {
            path: `/${AppRoutes.Both}/${AppRoutes.Calisthenics}`,
            end: false,
        },
        window.location.pathname
    );

    const isBothCalisthenicsAgeUrl = matchPath(
        {
            path: `/${AppRoutes.Both}/${AppRoutes.CalisthenicsAge}`,
            end: false,
        },
        window.location.pathname
    );
    const isMexUrl = matchPath(
        {
            path: `/${AppRoutes.Both}/${AppRoutes.Mex}`,
            end: false,
        },
        window.location.pathname
    );

    const isAgeWorkoutUrl = matchPath(
        {
            path: `/${AppRoutes.AgeWorkout}`,
            end: false,
        },
        window.location.pathname
    );

    const isPilatesUrl = matchPath(
        {
            path: `/${AppRoutes.Pilates}`,
            end: false,
        },
        window.location.pathname
    );

    const newBothType =
        isBoth &&
        newBothContentRoutes.find((route) => window.location.pathname.split("/").includes(route));

    if (isCalisthenicsUrl) {
        return FunnelTypes.calisthenics;
    } else if (isCalisthenicsAgeUrl) {
        return FunnelTypes.calisthenicsAge;
    } else if (isDynamicBothGoogle) {
        return extractBaseBothPath(window.location.pathname);
    } else if (isBoth || isBothGoogle) {
        if (isFriends) return FunnelTypes.friends;
        if (isOfferUrl) return FunnelTypes.offer;
        if (newBothType) return `${FunnelTypes.both}/${newBothType}`;
        if (isBothCalisthenicsUrl) return `${FunnelTypes.both}/${FunnelTypes.calisthenics}`;
        if (isBothCalisthenicsAgeUrl) return `${FunnelTypes.both}/${FunnelTypes.calisthenicsAge}`;
        if (isMexUrl) return `${FunnelTypes.both}/${FunnelTypes.mex}`;

        return FunnelTypes.both;
    } else if (isAgeWorkoutUrl) {
        return AppRoutes.AgeWorkout;
    } else if (isPilatesUrl) {
        return FunnelTypes.pilates;
    }

    return gender;
};
