export enum FlowTypes {
    Both = "both",
    Email = "email",
    Friends = "friends",
    Calisthenics = "calisthenics",
    Offer = "offer",
    Seniors = "seniors",
    Tiktok = "tiktok",
    Pilates = "pilates",
    Mex = "mex",
}
