import { createContext, useContext, useState } from "react";

interface AuthTokenContextType {
    token: string | null;
    setToken: (token: string) => void;
}

const AuthTokenContext = createContext<AuthTokenContextType | undefined>(undefined);

const AuthTokenProvider = ({ children }: { children: React.ReactNode }) => {
    const [token, setToken] = useState<string | null>(null);

    return (
        <AuthTokenContext.Provider value={{ token, setToken }}>
            {children}
        </AuthTokenContext.Provider>
    );
};

export const useAuthToken = () => {
    const context = useContext(AuthTokenContext);
    if (!context) throw new Error("useAuthToken must be used within an AuthProvider");

    return context;
};

export default AuthTokenProvider;
