import {
    getForcedControlGroup,
    getForcedTest1Group,
    getForcedTest2Group,
    getForcedTest3Group,
    getForcedTestGroup,
} from "@/utils/settings";

export enum ExperimentIds {
    // do not remove this is permanent exp for splitting users into segments
    SEGMENTS = "segments",
    SENIORS_FUNNEL = "webtest_seniors_funnel_FAEX_573_jan25",
    TIKTOK_FUNNEL = "webtest_tt_funnel_iter2_FAEX_642_feb25",
    WHATSAPP_CONNECTION = "webtest_whatsapp_connection_DEV_3978_feb25",
    BUNDLE_UPSELLS = "webtest_bundle_all_options_upsells_FAEX_683_mar25",
    INCREASE_MXN_PRICES = "webtest_increase_mxn_prices_FAEX_663_mar25",
    DISCOUNT_ON_CHECKOUT = "webtest_discount_on_checkout_FAEX_668_mar25",
    ANIMATED_PAYWALL = "webtest_animated_paywall_FAEX_651_mar25",
    PERSONALIZE_PAYWALL_MESSAGING = "webtest_personalize_paywall_messaging_FAEX_652_mar25",
    BEGINNERS_FUNNEL_V2 = "webtest_beginners_v2_FAEX_671_mar25",
    BOOST_ANNUAL_PLAN = "webtest_boost_annual_plan_FAEX_688_mar25",
    PAYPAL_DEFAULT = "webtest_paypal_default_FAEX_685_apr25",
    CORE_USERS_V2 = "webtest_core_users_v2_FAEX_693_apr25",
}

export const enum ExperimentVariants {
    CONTROL = "control",
    TEST = "test",
    TEST_1 = "test_1",
    TEST_2 = "test_2",
    TEST_3 = "test_3",
    NOT_ALLOCATED = "not_allocated",
    SEGMENT_A = "segment_A",
    SEGMENT_B = "segment_B",
}

export const TEST_VARIANTS = [
    ExperimentVariants.TEST,
    ExperimentVariants.TEST_1,
    ExperimentVariants.TEST_2,
    ExperimentVariants.TEST_3,
];

export const groupByForcedParams: Record<ExperimentVariants, string[]> = {
    [ExperimentVariants.CONTROL]: getForcedControlGroup(),
    [ExperimentVariants.TEST]: getForcedTestGroup(),
    [ExperimentVariants.TEST_1]: getForcedTest1Group(),
    [ExperimentVariants.TEST_2]: getForcedTest2Group(),
    [ExperimentVariants.TEST_3]: getForcedTest3Group(),
    [ExperimentVariants.NOT_ALLOCATED]: [],
    [ExperimentVariants.SEGMENT_A]: [],
    [ExperimentVariants.SEGMENT_B]: [],
};

// need to track paywall id for analytics
export const paywallExperimentsIds = [
    ExperimentIds.TIKTOK_FUNNEL,
    ExperimentIds.INCREASE_MXN_PRICES,
    ExperimentIds.DISCOUNT_ON_CHECKOUT,
    ExperimentIds.ANIMATED_PAYWALL,
    ExperimentIds.SENIORS_FUNNEL,
    ExperimentIds.PERSONALIZE_PAYWALL_MESSAGING,
    ExperimentIds.BOOST_ANNUAL_PLAN,
    ExperimentIds.PAYPAL_DEFAULT,
];
