export enum CountryCodes {
    GB = "GB",
    US = "US",
    CA = "CA",
    AU = "AU",
    DE = "DE",
    MX = "MX",
    CY = "CY",
    LT = "LT",
    CH = "CH",
    SK = "SK",
    EE = "EE",
    LV = "LV",
    SE = "SE",
    NO = "NO",
    PL = "PL",
    DK = "DK",
    CZ = "CZ",
    HU = "HU",
    AR = "AR",
    CL = "CL",
    BR = "BR",
}

export const enum CurrencyCodes {
    USD = "USD",
    GBP = "GBP",
    CAD = "CAD",
    AUD = "AUD",
    EUR = "EUR",
    MXN = "MXN",
    ARS = "ARS",
    CLP = "CLP",
    BRL = "BRL",
}

export const enum CurrencySymbols {
    GB = "£",
    US = "$",
    CA = "C$",
    AU = "A$",
    EU = "€",
    MX = "MXN",
    AR = "ARS",
    CL = "CLP",
    BR = "$R",
}

export const CurrencyCodeByCountryCode: Record<CountryCodes, CurrencyCodes> = {
    [CountryCodes.US]: CurrencyCodes.USD,
    [CountryCodes.GB]: CurrencyCodes.GBP,
    [CountryCodes.CA]: CurrencyCodes.CAD,
    [CountryCodes.AU]: CurrencyCodes.AUD,
    [CountryCodes.DE]: CurrencyCodes.EUR,
    [CountryCodes.MX]: CurrencyCodes.MXN,
    [CountryCodes.AR]: CurrencyCodes.USD,
    [CountryCodes.CL]: CurrencyCodes.CLP,
    [CountryCodes.CY]: CurrencyCodes.EUR,
    [CountryCodes.LT]: CurrencyCodes.EUR,
    [CountryCodes.CH]: CurrencyCodes.EUR,
    [CountryCodes.SK]: CurrencyCodes.EUR,
    [CountryCodes.EE]: CurrencyCodes.EUR,
    [CountryCodes.LV]: CurrencyCodes.EUR,
    [CountryCodes.SE]: CurrencyCodes.EUR,
    [CountryCodes.NO]: CurrencyCodes.EUR,
    [CountryCodes.PL]: CurrencyCodes.EUR,
    [CountryCodes.DK]: CurrencyCodes.EUR,
    [CountryCodes.CZ]: CurrencyCodes.EUR,
    [CountryCodes.HU]: CurrencyCodes.EUR,
    [CountryCodes.BR]: CurrencyCodes.BRL,
};

export const CurrencySymbolsByCurrencyCode: Record<CurrencyCodes, CurrencySymbols> = {
    [CurrencyCodes.USD]: CurrencySymbols.US,
    [CurrencyCodes.GBP]: CurrencySymbols.GB,
    [CurrencyCodes.CAD]: CurrencySymbols.CA,
    [CurrencyCodes.AUD]: CurrencySymbols.AU,
    [CurrencyCodes.EUR]: CurrencySymbols.EU,
    [CurrencyCodes.MXN]: CurrencySymbols.MX,
    [CurrencyCodes.ARS]: CurrencySymbols.AR,
    [CurrencyCodes.CLP]: CurrencySymbols.CL,
    [CurrencyCodes.BRL]: CurrencySymbols.BR,
};
