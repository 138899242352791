import { useEffect, useState } from "react";

import { setAnalyticsUserProperties } from "@/constants/amplitude";
import { APPLE_PAY_MERCHANT_ID } from "@/constants/env";

export default function usePaymentSystem() {
    const [isApplePayAvailable, setIsApplePayAvailable] = useState<boolean | null>(null);

    useEffect(() => {
        (async () => {
            if (window.ApplePaySession) {
                try {
                    const capabilities =
                        await window.ApplePaySession?.applePayCapabilities(APPLE_PAY_MERCHANT_ID);
                    const isApplePay =
                        capabilities?.paymentCredentialStatus === "paymentCredentialsAvailable";

                    setIsApplePayAvailable(isApplePay);
                    setAnalyticsUserProperties({ has_applepay: isApplePay });
                } catch (e) {
                    setIsApplePayAvailable(false);
                    setAnalyticsUserProperties({ has_applepay: false });
                }
            } else {
                setIsApplePayAvailable(false);
                setAnalyticsUserProperties({ has_applepay: false });
            }
        })();
    }, []);

    return {
        isApplePayAvailable,
    };
}
