import { UrlParams } from "./urlParams";

const params =
    typeof window !== "undefined"
        ? new URLSearchParams(window.location.search)
        : new URLSearchParams();
export const step = params.get(UrlParams.STEP);

const forcedControlGroup = params.get(UrlParams.FORCE_CONTROL_GROUP);
const forcedTestGroup = params.get(UrlParams.FORCE_TEST_GROUP);
const forcedTest1Group = params.get(UrlParams.FORCE_TEST_1_GROUP);
const forcedTest2Group = params.get(UrlParams.FORCE_TEST_2_GROUP);
const forcedTest3Group = params.get(UrlParams.FORCE_TEST_3_GROUP);

export const countryCodeParam = params.get(UrlParams.COUNTRY_CODE);
export const utmCampaign = params.get(UrlParams.UTM_CAMPAIGN);
export const utmSource = params.get(UrlParams.UTM_SOURCE);
export const utmContent = params.get(UrlParams.UTM_CONTENT);
export const email = params.get(UrlParams.EMAIL);
export const uuid = params.get(UrlParams.USER_ID);
export const friendsUserId = params.get(UrlParams.FRIENDS_USER_ID);
export const friendsContactId = params.get(UrlParams.FRIENDS_CONTACT_ID);
export const testFlow = params.get(UrlParams.E2E_TEST_FLOW);
export const language = params.get(UrlParams.LANGUAGE);
export const withSessionReplay = params.get(UrlParams.SESSION_REPLAY) === "on";
export const isDisabledPreventDuplicateFlow =
    params.get(UrlParams.DISABLE_PREVENT_DUPLICATE_FLOW) === "on";
export const impactRef = params.get(UrlParams.IMPACT_REF);
export const promoCode = params.get(UrlParams.PROMO);

export const generatedWorkoutUuid = params.get("generated_workout_uuid");

const getExperimentIdsArrayFromString = (forcedString: string | null) =>
    forcedString?.split(";") ?? [];

export const getForcedControlGroup = () => getExperimentIdsArrayFromString(forcedControlGroup);
export const getForcedTestGroup = () => getExperimentIdsArrayFromString(forcedTestGroup);
export const getForcedTest1Group = () => getExperimentIdsArrayFromString(forcedTest1Group);
export const getForcedTest2Group = () => getExperimentIdsArrayFromString(forcedTest2Group);
export const getForcedTest3Group = () => getExperimentIdsArrayFromString(forcedTest3Group);

export const enum UTMSources {
    TikTok = "tiktok",
}
