export const enum UrlParams {
    FORCE_TEST_GROUP = "force_test_group_ids",
    FORCE_TEST_1_GROUP = "force_test_1_group_ids",
    FORCE_TEST_2_GROUP = "force_test_2_group_ids",
    FORCE_TEST_3_GROUP = "force_test_3_group_ids",
    FORCE_CONTROL_GROUP = "force_control_group_ids",
    EMAIL = "email",
    USER_ID = "userID",
    STEP = "step",
    UTM_CAMPAIGN = "utm_campaign",
    UTM_SOURCE = "utm_source",
    UTM_CONTENT = "utm_content",
    COUNTRY_CODE = "country_code",
    FRIENDS_USER_ID = "user_id",
    FRIENDS_CONTACT_ID = "contact_id",
    E2E_TEST_FLOW = "e2e_test_flow",
    LANGUAGE = "lng",
    PROMO = "promo",
    SESSION_REPLAY = "session_replay",
    DISABLE_PREVENT_DUPLICATE_FLOW = "disable_prevent_duplicate_flow",
    IMPACT_REF = "im_ref",
}

export const enum UtmCampaigns {
    BodyScan = "bodyscan",
}
